import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Про компанію
			</title>
			<meta name={"description"} content={"Створення вашого ідеального житлового простору"} />
			<meta property={"og:title"} content={"Про компанію"} />
			<meta property={"og:description"} content={"Створення вашого ідеального житлового простору"} />
			<meta property={"og:image"} content={"https://progresservise.com/images/image1.jpg"} />
			<link rel={"shortcut icon"} href={"https://progresservise.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://progresservise.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://progresservise.com/images/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://progresservise.com/images/image4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" lg-text-align="center" font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Ознайомтеся з нашими послугами ремонту
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					У RenovateRight ми надаємо широкий спектр послуг з ремонту, які охоплюють кожен аспект вашого проекту з покращення житла. Незалежно від того, чи прагнете ви освіжити окрему кімнату, чи плануєте комплексний капітальний ремонт всієї оселі, наша кваліфікована команда готова допомогти вам. Ми пишаємося нашою здатністю поєднувати функціональність з естетикою, гарантуючи, що кожен проект не тільки відповідає, але й перевершує ваші очікування.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					text-align="center"
				>
					Отримаати повний каталог
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="0px 0px 34px 0px" color="--dark" lg-text-align="center" font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Наші послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Повний ремонт будинку
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Капітальний ремонт: Перетворіть усю оселю, щоб вона відповідала вашому стилю та потребам. Включає зміни планування, нові інсталяції та оновлення електричних і сантехнічних систем.
						<br />
						<br />
						Модернізація: Оновіть свій будинок за допомогою новітніх побутових технологій, енергоефективних рішень та сучасної естетики.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
							Реконструкція кухні та ванної кімнати
						</Text>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Меблі на замовлення: Виготовлені на замовлення шафи, які максимізують простір і підвищують функціональність.
							<br />
							<br />
							Світильники та фурнітура: Високоякісні раковини, душові кабіни, змішувачі та аксесуари, що поєднують стиль і довговічність.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Екологічна модернізація
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Практики сталого розвитку: Впроваджуйте енергоефективні вікна, сонячні панелі та інші екологічні технології, щоб зменшити вуглецевий слід вашого будинку.
						<br />
						<br />
						Зелені матеріали: Використання екологічно чистих, нетоксичних матеріалів, безпечних для вашої родини та планети.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Вітальні та їдальні
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Рішення для підлоги: Від твердої деревини до сучасної плитки - ми пропонуємо широкий вибір підлогових покриттів на будь-який смак.
						<br />
						<br />
						Концепції освітлення: Створіть ідеальну атмосферу з нашими індивідуальними світловими рішеннями, включаючи розумні світильники, якими ви можете легко керувати.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Екстер'єр та ландшафтний дизайн
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Підвищення привабливості бордюру: Прикрасьте зовнішній вигляд вашого будинку за допомогою наших послуг з озеленення, фарбування та покращення фасаду.
						<br />
						<br />
						Житлові простори на відкритому повітрі: Проектуйте та будуйте ідеальні відкриті майданчики для відпочинку та розваг.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Хочете дізнатися більше про те, як RenovateRight може перетворити ваш будинок? Наша команда готова обговорити ваші конкретні потреби та допомогти вам спланувати наступний проект ремонту. Зв'яжіться з нами за контактними даними, вказаними на нашій головній сторінці, щоб отримати детальну консультацію.
			</Text>
			<Link
				href="/contact-us"
				padding="12px 24px 12px 24px"
				color="--light"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="20px 16px 0px 0px"
				transition="background-color 0.2s ease-in-out 0s"
				sm-margin="0px 0px 16px 0px"
				sm-text-align="center"
				background="--color-green"
				hover-transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-primary"
				text-align="center"
			>
				Контакти
			</Link>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});